<template>
  <div class="inner-section">
    <card></card>
    <body-card>
    <template v-slot:headerTitle>
      <h4 class="card-title">{{$t('elearning_tim.nominated_trainee_trainer')}}</h4>
    </template>
    <template v-slot:headerAction>
      <router-link to="trainee-selection" class="btn btn-add mr-2">{{ $t('elearning_tim.trainee_selection') }} {{ $t('globalTrans.list') }}</router-link>
      <router-link to="/training-e-learning-service/tpm/trainer-selection" class="btn btn-add">{{ $t('elearning_tim.trainer_selection') }} {{ $t('globalTrans.list') }}</router-link>
    </template>
    <template v-slot:body>
      <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
        <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
          <b-row>
            <b-col lg="6" sm="6">
              <ValidationProvider name="Nominated Organization Type"  vid="org_type_id" rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="org_type_id"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('elearning_iabm.nominated_organization_type')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                      plain
                      v-model="formData.org_type_id"
                      :options="orgTypeList"
                      id="org_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="6" sm="6" v-if="formData.org_type_id === 1">
              <ValidationProvider name="Nominated Organization"  vid="org_id">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="org_id"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('elearning_iabm.nominated_organization')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="formData.org_id"
                      :options="nominatedMocList"
                      id="org_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="6" sm="6" v-if="formData.org_type_id === 2">
              <ValidationProvider name="Nominated Organization"  vid="org_id">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="org_id"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('elearning_iabm.nominated_organization')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="formData.org_id"
                      :options="nominatedPrivateList"
                      id="org_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="6" sm="6">
              <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="fiscal_year_id"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('elearning_config.fiscal_year')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="formData.fiscal_year_id"
                      :options="fiscalYearList"
                      id="fiscal_year_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="6" sm="6">
              <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="circular_memo_no"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                      plain
                      v-model="formData.circular_memo_no"
                      :options="circularList"
                      id="circular_memo_no"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="6" sm="6">
              <ValidationProvider name="Select Payment Type" vid="payment_type" rules="">
                  <b-form-group
                  class="row"
                  label-cols-sm="5"
                  label-for="registration_for"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('globalTrans.type')}} <span class="text-danger">*</span>
                </template>
                <b-form-radio-group
                  v-model="formData.registration_for"
                  :options="userTypeList"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-radio-group>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <br/>
          <b-overlay :show="mainloading">
            <body-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_tpm.registration_list')}}</h4>
              </template>
              <template v-slot:body>
                <b-table-simple class="mb-0" hover small caption-top responsive bordered>
                  <b-tr>
                    <b-th class="text-center">{{$t('globalTrans.sl_no')}}</b-th>
                    <b-th>{{$t('globalTrans.name')}}</b-th>
                    <b-th>{{$t('globalTrans.mobile')}}</b-th>
                    <b-th>{{$t('globalTrans.designation')}}</b-th>
                    <b-th>{{$t('globalTrans.select')}}</b-th>
                  </b-tr>
                  <b-tr v-for="(train,index) in formData.personalList" :key="index">
                    <b-td class="text-center">{{$n(index+1)}}</b-td>
                    <b-td>
                      {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                    </b-td>
                    <b-td>
                      {{ train.mobile }}
                    </b-td>
                    <b-td>
                      {{ ($i18n.locale==='bn') ? train.designation_bn : train.designation_en }}
                    </b-td>
                    <b-td>
                      <b-col>
                        <b-form-checkbox
                          :id="'checkbox-' + train.id"
                          v-model="train.select"
                          :name="'checkbox-' + train.id"
                          value=1
                          unchecked-value=0
                        >
                        </b-form-checkbox>
                      </b-col>
                    </b-td>
                  </b-tr>
                  <template v-if="formData.personalList.length === 0">
                    <tr>
                      <th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                    </tr>
                  </template>
                </b-table-simple>
              </template>
            </body-card>
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col text-right">
                <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
                <!-- <router-link to="trainee-selection" class="mr-1 btn btn-danger btn-sm">{{ $t('globalTrans.cancel') }}</router-link> -->
              </div>
            </div>
          </b-overlay>
        </b-form>
      </ValidationObserver>
    </template>
    </body-card>
  </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { nominatedStore, circularPublicationList, traineeTrainerApplication } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    data () {
      return {
        search: {
          org_id: 0,
          mobile: ''
        },
        formData: {
          registration_for: 2,
          check_time: '',
          circular_memo_no: 0,
          fiscal_year_id: 0,
          org_id: 0,
          org_type_id: 0,
          personalList: []
        },
        orgType: [
          {
            value: 1,
            text: 'MOC',
            text_en: 'MOC',
            text_bn: 'এমওসি'
          },
          {
            value: 2,
            text: 'Private',
            text_en: 'Private',
            text_bn: 'ব্যক্তিগত'
          },
          {
            value: 3,
            text: 'Others',
            text_en: 'Others',
            text_bn: 'অন্যান্য'
          }
        ],
        applied: [],
        officeTypeList: [],
        officeList: [],
        trainingCategoryList: [],
        trainingTitleList: [],
        load: false,
        mainloading: false,
        detailsErrors: [],
        circularList: [],
        circularLoading: false,
        circularErrorMsg: false,
        trainerProfileError: ''
      }
    },
    created () {
      this.getCircularList()
      this.formData = Object.assign({}, this.formData, {
        fiscal_year_id: this.$store.state.currentFiscalYearId
      })
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      orgTypeList: function () {
        return this.orgType.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
      },
      nominatedMocList () {
        return this.$store.state.TrainingElearning.commonObj.nominatedOrgList.filter(item => item.status === 1 && item.org_type_id === 1)
      },
      nominatedPrivateList () {
        return this.$store.state.TrainingElearning.commonObj.nominatedOrgList.filter(item => item.status === 1 && item.org_type_id === 2)
      },
      userTypeList () {
        const userList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      }
    },
    watch: {
      'formData.fiscal_year_id': function (newValue) {
        this.getCircularList()
      },
      'formData.org_type_id': function (newValue) {
        this.loadData()
      },
      'formData.org_id': function (newValue) {
        this.loadData()
      },
      'formData.circular_memo_no': function (newValue) {
        this.loadData()
      },
      'formData.registration_for': function (newValue) {
        this.loadData()
      }
    },
    mounted () {
      flatpickr('.fromDate', {})
    },
    methods: {
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.formData.fiscal_year_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoading = false
      },
      async loadData () {
        if (this.formData.circular_memo_no && this.formData.org_type_id) {
          this.mainloading = true
          const data = this.formData
          data.personalList = []
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, traineeTrainerApplication, data)
          if (result.success) {
            this.applied = result.applied
            const datas = result.data.map(item => {
              const desigData = {}
              let appliedObj = {}
              appliedObj = this.applied.find(applied => applied.personal_info_id === parseInt(item.personal_info_id))
              if (typeof appliedObj !== 'undefined') {
                desigData.select = '1'
                desigData.training_application_id = appliedObj.id
              } else {
                desigData.select = 0
                desigData.training_application_id = 0
              }
              return Object.assign({}, item, desigData)
            })
            this.formData.personalList = datas
          } else {
            this.formData.personalList = []
          }
          this.mainloading = false
        }
      },
      getOfficeTypeList (orgId) {
        const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
          return OfficeTypeList.filter(item => item.org_id === orgId)
        }
        return OfficeTypeList
      },
      getOfficeList (officeTypeId) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
          return officeList.filter(item => item.office_type_id === officeTypeId)
        }
        return officeList
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      async createData () {
        this.mainloading = true
        let result = null
        result = await RestApi.postData(trainingElearningServiceBaseUrl, nominatedStore, this.formData)
        if (result.success) {
          // this.$socket.emit('send-notification', result.notification)
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$refs.form.reset()
          // this.$router.push('/training-e-learning-service/tim/trainee-selection')
        }
        this.mainloading = false
      }
    },
    beforeDestroy () {
      clearInterval(this.inputTimer)
    }
}
</script>
